import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn, } from '../../utils/gaia';
import Axios from 'axios';
const Landing = (props) => {

  const navigate = useNavigate();

  useEffect(()=>{
    if(isLoggedIn()){navigate(`/home`)}
  },[])

  return (
    <>
        <main class="common-bg">
            <section class="microsite-bg-img">
                <div class="nav-home">
                    <a href="/"><img src={Images.homebtn} alt="" /></a>
                </div>
                <div class="microsite-main">
                    <div class="container">
                        <div class="microsite-login">
                            <div class="micro-login-box">
                                <div class="game-logo catech-logo">
                                    <img src={Images.logo} alt="" />
                                </div>
                                <div class="btn">
                                    <a href="/scan"> <img src={Images.loginbtn} alt="" /> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
  );
}

export default Landing;
