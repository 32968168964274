import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { QRCode } from 'react-qrcode-logo';
import { useNavigate } from 'react-router-dom';
import { loadError, loadSuccess, APIHOST, isLoggedIn, getToken, showLoading, getCandy } from '../../utils/gaia';
import Axios from 'axios';
const Home = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [member, setMember] = useState([])
    const [qrCode, setQrCode] = useState("")

  useEffect(()=>{
    if(!isLoggedIn()){navigate(`/`)}
    loadData()
  },[])

  const loadData = () =>{
    setLoading(true)
    showLoading("Loading Wallet, please wait...")
    Axios.post(`${APIHOST}nonMember/verifyQr`, {
        qr : getToken(),
        session: getCandy()
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        setLoaded(true);
        loadSuccess(data.msg)
        setMember(data.member)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error ?? er.response.data.message)
        }else{
          loadError("A network error occured.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }
 
  return (
    <>
        <main class="common-bg">
            <section class="microsite-bg-img">
                <div class="nav-home">
                    <a href="#"><img src={Images.homebtn} alt="" /></a> 
                </div>
                <div class="microsite-main microsite-member-qr">
                    <div class="container">
                        <div class="microsite-login">
                            {loaded &&<div class="micro-login-box">
                                <div class="qr-scaner">
                                    <QRCode value={getToken()} fgColor="#a11c3e" size="185" />
                                </div>

                                <div class="micro-member-info">
                                    <div class="micro-scan-detail">
                                        <div class="member-chances-box">
                                            <h4>BALANCE CHANCES</h4>
                                            <p>{member.chances}</p>
                                        </div>

                                        <div class="member-point-box">
                                            <h4>BALANCE POINTS</h4>
                                            <p>{member.points}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
  );
}

export default Home;
