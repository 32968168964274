import React, { Component, useEffect, useState } from 'react';
import { Images } from '../../utils/Images';
import { Scanner } from '@yudiel/react-qr-scanner';
import { useNavigate } from 'react-router-dom';
import { loadError, loadSuccess,  APIHOST,  isLoggedIn, setCandy, setToken, showLoading, updateloginStatus} from '../../utils/gaia';
import Axios from 'axios';
const ScanQr = (props) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isPaused, setIspaused] = useState(false);

    useEffect(()=>{
        if(isLoggedIn()){navigate(`/home`)}
    },[])

    const scanResult = (result) =>{
        console.log(result)
        if (result.length > 0 && !isPaused) {
            setIspaused(true)
            checkQR(result[0]?.rawValue);
        }
    }

    const checkQR = (data) =>{
        if(data == ""){
          loadError("Please scan the qr code")
          setIspaused(false)
          return;
        }
        setLoading(true)
        showLoading("Verifying QR code, please wait...")
        Axios.post(`${APIHOST}nonMember/login`, {
            qr : data,
        }).then(function(res){
            let {data} = res;
            setLoading(false)
            loadSuccess(data.msg)
            setCandy(data.session)
            setToken(data.stamp)
            updateloginStatus(true)
            setTimeout(()=>{  window.location.href =  "/home" },1500)
        }).catch(function(er){
          setLoading(false)
          setTimeout(()=>setIspaused(false), 2000);
          if(typeof(er.response) !== "undefined"){
            if(er.response.status == 404 || er.response.status == 500){
              loadError(er.response.data.error ?? er.response.data.message)
            }else{
              loadError("A network error occured.")
            }
          }else{
            loadError(er.message)
          }
          
        })
    }

    return (
        <>
            <main class="common-bg">
                <section class="microsite-bg-img">
                    <div class="nav-home">
                        <img src={Images.homebtn} alt="" />   
                    </div>
                    <div class="microsite-main">
                        <div class="container">
                            <div class="microsite-login">
                                <div class="micro-login-box">
                                    <div class="scan-gam-logo catech-logo">
                                        <a href="#"><img src={Images.logo} alt="" /></a>
                                    </div>
                                    <div style={{"margin-top" : "30px"}}>
                                        <Scanner scanDelay={3000} allowMultiple={true} paused={isPaused} onScan={(result) => scanResult(result)} constraints={{ facingMode: 'environment' }} classNames={{container : "scanner-scan"}}  />
                                    </div>

                                    <div class="micro-scan-info"><p>scan qr code</p></div>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default ScanQr;
